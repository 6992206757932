// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { graphql } from 'gatsby'

import orderBy from 'lodash/orderBy'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import LinesEllipsis from 'react-lines-ellipsis'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import formattedDate from '../methods/formatted-date'
import getTimestamp from '../methods/get-timestamp'
import timeAgo from '../methods/time-ago'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'News',
  slug: '/news',
  abstract: 'News',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'News', slug: '/news' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query NewsPageQuery {
    allNewsJson {
      nodes {
        published
        shortAbstract
        routeSlug
        title
        source
        sourcePub
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 1440
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    news1: file(relativePath: { eq: "news/image0.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    news2: file(relativePath: { eq: "news/image1.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    news3: file(relativePath: { eq: "news/image3.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    news4: file(relativePath: { eq: "news/image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, mediaState, ...restProps }) => {
  const {
    allNewsJson: { nodes },
  } = data
  const orderedNodes = orderBy(nodes, ['published'], ['desc'])

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...restProps}>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
          <h1>Recently featured in</h1>
          <div
            className="as-paragraph"
            style={{
              borderTop: '4px solid var(--green2)',
              background: '#fff',
              padding: '1.1rem',
              marginBottom: '1.1rem',
              maxWidth: 'unset',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <div
              className="as-paragraph"
              style={{ display: 'flex', maxWidth: 'unset' }}
            >
              <GatsbyImage
                style={{ flex: 1, marginRight: '0.255rem' }}
                objectFit="contain"
                image={getImage(data.news1)}
              />
              <GatsbyImage
                style={{ flex: 1, marginRight: '0.255rem' }}
                objectFit="contain"
                image={getImage(data.news2)}
              />
              <GatsbyImage
                style={{ flex: 1, marginRight: '0.255rem' }}
                objectFit="contain"
                image={getImage(data.news3)}
              />
              <GatsbyImage
                style={{ flex: 1 }}
                objectFit="contain"
                image={getImage(data.news4)}
              />
            </div>
          </div>
          {map(orderedNodes, (node, index) => (
            <Link to={node.source} className="news-item as-paragraph">
              <Row
                gutter={[
                  { xs: 16, sm: 20, md: 24, lg: 28, xl: 32, xxl: 36 },
                  { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
                ]}
              >
                <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4}>
                  <p style={{ textAlign: 'right' }}>
                    {node.sourcePub}
                    <br />
                  </p>
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
                  <h3>{node.title}</h3>
                </Col>
                <Col xs={0} sm={0} md={5} lg={5} xl={5} xxl={5}>
                  <p style={{ textAlign: 'left' }}>
                    {timeAgo(getTimestamp(node.published) / 1000)}{' '}
                    <span className="outlink">☞</span>
                  </p>
                </Col>
              </Row>
            </Link>
          ))}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <aside
            style={{
              borderTop: '4px solid var(--orange1)',
              marginTop: '1.1rem',
              padding: '1.1rem',
              background: 'var(--gray2)',
              borderBottomLeftRadius: '0.55rem',
              borderBottomRightRadius: '0.55rem',
              overflow: 'hidden',
            }}
          >
            <h2>Also explore...</h2>
            <Row
              gutter={[
                { xs: 8, sm: 12, md: 16, lg: 20, xl: 24, xxl: 28 },
                { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              ]}
              className="adjust-col-spacing"
            >
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <h3>Aura Talks</h3>
                <p>
                  Aura Talks is a podcast dedicated to sharing the journey of
                  Aura and how its vision continues to grow, evolve and
                  crystallize in the context of Auroville.
                </p>
                <p>
                  <Link className="alt" to="/aura-talks">
                    See all Aura Talks&nbsp;<span className="hand">☞</span>
                  </Link>
                </p>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <h3>Community Events</h3>
                <p>
                  A list of our upcoming and past events held in the community
                  including presentations of our project, launch events as well
                  as the Unfold Your Skills workshops.
                </p>
                <p>
                  <Link className="alt" to="community-events">
                    See all Community Events ☞
                  </Link>
                </p>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                <h3>Aura Stories</h3>
                <p>
                  Aura Stories is a mini series that sheds light on the human
                  stories, interactions and exchanges from the Aura Network. Who
                  better to describe the fruitful experiences on the Network
                  than members themselves?
                </p>
                <p>
                  <Link className="alt" to="/aura-stories">
                    See all Aura Stories ☞
                  </Link>
                </p>
              </Col>
            </Row>
          </aside>
        </Col>
      </Row>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // dispatch => ({
  //   updateWebsiteTheme(payload) {
  //     dispatch(updateWebsiteTheme(payload))
  //   },
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
